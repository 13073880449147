<template>
	<div class="headTop">
		<div class="headleft">
			<span class="headicon ydbody" @click="onHeadblock"><i class="el-icon-s-unfold"></i></span>
			<div class="logo webbody" @click="ontuindex">
				<span><img src="https://erlangcha.oss-cn-beijing.aliyuncs.com/usergongju/userLogo1.png" alt=""></span>
			</div>
			<div class="headCenter" v-if="$route.meta.account">
				<div class="wenzi webbody">授权账号选择：</div>
				<el-select v-model="accoutValue" @change="onTopxuanze" placeholder="请选择">
					<el-option v-for="item in accoutList" :key="item.id" :label="item.display_name" :value="item.id"></el-option>
				</el-select>
				<el-button type="text" icon="el-icon-circle-plus-outline" @click="onAddAccout">添加账号</el-button>
			</div>
		</div>
		
		<div class="headright" v-if="!$route.meta.headtop">
			<!-- <div class="updatetime">
				<i class="iconfont icon-tishi"></i>
				数据更新于：{{timeJinri}}
				 <el-date-picker v-model="timeValue" @change="onPickerhead" type="daterange" align="right" unlink-panels range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" :clearable="false"></el-date-picker>
			</div> -->
			<div class="headUser">
				
				<el-button type="primary" @click="toLogon()" v-if="userInfo == null">登录</el-button>
				<el-dropdown v-else>
					<span class="el-dropdown-link userinfo">
						<div class="userimg">
							<img v-if="userInfo.wechat_head" :src="onWechat(userInfo.wechat_head)" alt="" srcset="">
							<img v-else src="https://erlangcha.oss-cn-beijing.aliyuncs.com/indeximg/toux2.png" alt="" srcset="">
						</div>
						<!-- <i class="iconfont icon-zhanghu"></i> -->
						<!-- {{userInfo.wechat_name}} -->
						<div class="name">
							{{Base64.decode(userInfo.wechat_name)}}
						</div>
					</span>
					<el-dropdown-menu slot="dropdown" class="tuichu">
						<el-dropdown-item >
							<span>{{userInfo.account}}</span>
						</el-dropdown-item>
						<el-dropdown-item v-if="!_isMobile">
							<span @click="onLoginout">退出登录</span>
						</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
			<!-- <Login ref="Login"></Login> -->
			<LoginApp ref="LoginApp"></LoginApp>
		</div>
		<!-- <ReplacePhone ref="ReplacePhone"></ReplacePhone> -->
	</div>
</template>

<script>
// import ReplacePhone from '@/views/mobphone/replacephone';
import Login from '@/views/login/index';
import LoginApp from './loginapp.vue';
import moment from "moment";//时间解析
export default {
	data () {
		return {
			timeJinri:moment(new Date()).format("YYYY-MM-DD HH:MM:SS"),
			timeValue:[new Date(), new Date()],
			userInfo:null,
			accoutValue:'',//账户选择
			accoutList:[],
		}
	},
	watch:{
		$route(to, from){
			if(localStorage.getItem('TZ-USER')){
				this.onwebLog()
				this.onaccDiaoyong()
			}
		}
	},
	components:{
		Login,
		LoginApp
		// ReplacePhone
	},
	mounted(){
		// this.onPickerhead(localStorage.getItem('TZ-USER'))
		this.userInfo = JSON.parse(localStorage.getItem('TZ-USER'))
		this.onwebLog()
		if(this.$route.title != '绑定手机号'){
			this.onaccDiaoyong()
		}
		// console.log(this.$route.path)
	},
	
	methods: {
		onasd(){
			this.$router.push('/mobilePhone')
		},
		onUserToken(){
			console.log(this.$route.meta.title)
			if(this.$route.meta.title == '绑定手机号'){return}
			var prame = {
				token:this.$route.query.token
			}
			this.$service.get(this.$api.personalMsg,prame, (res)=> {
				if(res.code == '200'){
					localStorage.setItem('TZ-USER', JSON.stringify(res.data))
					this.$router.go(0)
				}
			})
		},
		// 调用页面acc接口
		onaccDiaoyong(){
			if(localStorage.getItem('TZ-USER')){
				if(this.$route.path == '/dataShow'){
					this.ongetaccounts()
				}
			}
		},
		//weblog路由转换调用接口
		onwebLog(){
			var prame = {
				user_id: JSON.parse(localStorage.getItem('TZ-USER')) ? JSON.parse(localStorage.getItem('TZ-USER')).user_id : 0,
				app_name: 'usertool',
				domain: window.location.host,
				uri: this.$route.fullPath,
				client_ip: '',
				method: 'get',
			}
			this.$service.post(this.$api.webLog,prame, (res)=> {
				if(res.code == '200'){
				}
			})
		},
		//获取授权账号列表
		ongetaccounts(){
			if(localStorage.getItem('ACCOUT-VALUE')){
				this.accoutValue = JSON.parse(localStorage.getItem('ACCOUT-VALUE'))
				this.$parent.accoutValueClick(this.accoutValue)
			}
			this.$service.get(this.$api.getaccounts,{}, (res)=> {
				if(res.code == '200'){
					this.accoutList = res.data
					if(res.data.length == 0){
						localStorage.removeItem('ACCOUT-VALUE')
						this.accoutValue = ''
					}
					res.data.forEach((v,k) => {
						// console.log(v.id,123123)
						if(v.id != JSON.parse(localStorage.getItem('ACCOUT-VALUE'))){
							localStorage.removeItem('ACCOUT-VALUE')
							this.accoutValue = ''
						}
					});
				}
			})
		},
		// 账号选择修改缓存
		onTopxuanze(){
			localStorage.setItem('ACCOUT-VALUE', JSON.stringify(this.accoutValue))
			this.$parent.accoutValueClick(this.accoutValue)
		},
		onHeadblock(){
			this.$parent.onmenuBarBlock()
		},
		//按钮登录
		toLogon(){
			this.$refs.LoginApp.onLogin()
			// this.$refs.Login.toLogon()
			// window.location.href = process.env.VUE_APP_URL_LOGIN_API + '/user/login/wechat_login?redirect_url=' + escape(window.location.href);
		},
		//时间选择
		// onPickerhead(){
		// 	var timeValue = {
		// 		online_start_time:this.timeValue ? moment(this.timeValue[0]).format("YYYY-MM-DD") : "",
		// 		online_end_time:this.timeValue ? moment(this.timeValue[1]).format("YYYY-MM-DD") : "",
		// 	}
		// 	this.$parent.onPickerhead(timeValue);
		// },
		//退出登录
		onLoginout(){
			var param = {
				token:JSON.parse(localStorage.getItem('TZ-USER')).token 
			}
			this.$service.post(this.$api.logout,param, (res)=> {
				if(res.status_code == '0'){
					localStorage.removeItem("TZ-USER")
					this.$router.go(0)
					// this.onLoginApp()
				}
			})
			
		},
		//添加账户
		onAddAccout(){
			var url = window.location.origin
			var indxurl = 'https://qianchuan.jinritemai.com/openapi/qc/audit/oauth.html?app_id=1705047257350187&state=abcdefg&scope=%5B20000000%2C21000000%2C22000000%2C23000000%2C240000005D&material_auth=1&redirect_uri=' + url +'/return'
			window.open(indxurl,'_self');
		},
		//返回首页
		ontuindex(){
			if(this.$route.path != '/'){
				this.$router.push('/')
			}
		},
		// 头像地址替换
		onWechat(url){
			var Url;
			Url = url.replace("http://","https://")
			return Url
		},
		onReplace(){
			this.$refs.ReplacePhone.ondialogPhone()
		}
	}
}
</script>

<style lang="scss" scoped>
.headTop{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 60px;
	background: #fff;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding:0 30px;
	z-index: 100;
	.headleft{
		display: flex;
		align-items: center;
		.logo{
			cursor: pointer;
			// font-size: 24px;
			font-weight: 500;
			display: block;
			img{
				width: 300px;
			}
		}
		.headCenter{
			margin-left: 20px;
			display: flex;
			align-items: center;
		}
	}
	.headright{
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: #666;
		i{
			font-size: 13px;
		}
		.updatetime{
			.el-date-editor{
				width: 250px;
				height: 33px;
				line-height: 33px;
				margin: 0 20px;
				background: #f2f2f2;
				border-color: #f2f2f2;
			}
		}
		.userinfo{
			display: flex;
			align-items: center;
			.userimg{
				width: 40px;
				height: 40px;
				border-radius: 100%;
				overflow: hidden;
				margin-right: 10px;
			}
		}
	}
	.headUser{
		margin-left: 50px;
	}
}
.tuichu{
	.el-dropdown-menu__item{
		padding: 0;
		text-align: center;
		span{
			padding: 0 20px;
		}
	}
}
@media screen and (max-width: 750px) {
    .headTop{
		padding: 0 .25rem;
		padding-left: 0;
		height: 1rem;
		background: #f9f9f9;
		.headleft{
			font-size: .3rem;
			display: flex;
			align-items: center;
			.headicon{
				display:block;
				padding: 0 .3rem;
				line-height: 1rem;
				height: 1rem;
				background: #f2f2f2;
				i{
					padding-top: .3rem;
					font-size: .4rem;
				}
			}
			.headCenter{
				margin-left: .2rem;
			}
		}
		.headright{
			.userinfo{
				.userimg{
					width: .6rem;
					height: .6rem;
				}
			}
			.headUser{
				margin-left: 0;
				.name{
					width: .8rem;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					color: #777;
					-webkit-line-clamp: 1;
				}
			}
		}
	}
}

</style>
<style lang="scss">
.headright{
	.el-date-editor{
		.el-input__icon,.el-range-separator{
			line-height: 26px;
		}
		.el-range__close-icon{
			width: 0;
		}
		.el-range-input{
			background: #f2f2f2;
		}
	}
}

</style>
