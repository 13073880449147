<template>
	<div class="iframe-login">
		<el-dialog title="" :visible.sync="confitionDialog" append-to-body custom-class='login-dialog' width="480px" @close="onloginclose" :close-on-click-modal='false' :close-on-press-escape='false'>
			<i class="el-icon-close guanbi" @click="onloginclose"></i>
			<iframe v-if="confitionDialog" ref="fIframe" id="myFrame" class="iframeClass" src="https://server-api.erlangcha.com/serverlogin_v2" frameborder="0"></iframe>
		</el-dialog>
	</div>
</template>

<script>
export default {
	data () {
		return {
			confitionDialog:false,
		}
	},
	watch:{
		$route(){
			this.onLogin()
		}
	},
	mounted(){
		this.onLogin()
	},

	methods: {
		// pc登录注册iframe
		onPcLogin(){
			var that = this
			window.addEventListener('message',function(event){
				//此处执行事件
				console.log('监听到子页面的传参')
				that.onUserTokenpc(event.data.token) //有token获取个人信息
				console.log(event.data)
			})
		},
		//远程登录
		onLogin(){

			if(!localStorage.getItem('TZ-USER')){ 
				if(this.$route.query.s_token || this.$route.query.token){
					console.log(123123)
					if(this._isMicrom){ //pc版
						this.onUserTokenpc() //有token获取个人信息
					}else{//移动版
						this.onUserTokenyd() //有token获取个人信息
					}
				}
				else{
					if(this._isMicrom){ //pc版
						this.confitionDialog = true
						this.onPcLogin()
						// this.$confirm('请先登录后查看页面', '提示', {
						// 	confirmButtonText: '确定',
						// 	cancelButtonText: '取消',
						// 	type: 'warning'
						// }).then(() => {
						// 	this.onLoginApp()//没有就跳转页面去登录
						// 	// window.location.href = process.env.VUE_APP_URL_LOGIN_API + '/user/login/wechat_login?redirect_url=' + escape(window.location.href);
						// }).catch(() => {
								
						// });
					}else{//移动版
						window.location = this.loginUrl+'/apiserver/login/index?s_url=' + window.location.href //没有就跳转授权
					}
				}
			}
		},
		//pc获取个人信息
		onUserTokenpc(token){
			if(!token){return}
			console.log(window.location)
			var prame = {
				token:token
			}
			this.$service.get(this.$api.get_user_profile,prame, (res)=> {
				if(res.status_code == '0'){
					localStorage.setItem('TZ-USER', JSON.stringify(res.data))
					setTimeout(() => {
						location.reload()
                		// window.location.href = window.location.href.slice(0,window.location.href.indexOf('s_token')-1)
                		// window.location.href = '/';
					}, 1000);
				}
			})
		},
		//移动端获取个人信息
		onUserTokenyd(){
			if(this.$route.meta.title == '绑定手机号'){return}
			var prame = {
				token:this.$route.query.token
			}
			this.$service.get(this.$api.personalMsg,prame, (res)=> {
				if(res.code == '200'){
					localStorage.setItem('TZ-USER', JSON.stringify(res.data))
					if(this.$route.query.token){
						window.location = window.location.origin + '/'
					}
				}
			})
		},
		onloginclose(){
			this.confitionDialog = false
			// localStorage.removeItem("TZ-LOGIN")
			// document.getElementById("myFrame").contentWindow.onclearInterval();
			// this.$refs.fIframe.onclearInterval()
			// console.log(document.getElementById("myFrame").contentWindow)
		}
	}
}
</script>

<style lang="scss">
.iframeClass{
	width: 550px;
	height: 602px;
	margin-left: -73px;
}
.login-dialog{
	width: 480px;
	height: 602px;
	overflow: hidden;
	border-radius: 20px !important;
	.el-dialog__header{
		display: none;
	}
	.el-dialog__body{
		padding: 0;
		position: relative;
		.guanbi{
			position: absolute;
			right: 20px;
			top: 20px;
			cursor: pointer;
			color: #fff;
			font-size: 22px;
			
		}
	}
}
</style>
