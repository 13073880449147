import { userInfo } from "os";
var HOST = process.env.VUE_APP_URL;
// var HOST = "/api";
// var HOST = "";
export default {
    HOST,
    /**
     * @method GET
     * @params banner
     * @return {"code":200, "msg":"success"}
     */
     getUserProfiles: HOST + '/serverapi/user/data/get_user_profile',//token获取用户信息
     get_phone_code: HOST + "/serverapi/user/auth/get_phone_code",//手机号绑定 - 发送短信验证码
     post_bing_phone: HOST + "/serverapi/user/auth/post_bing_phone",//手机号绑定 - 发送短信验证码
     getRepairOrderJs: HOST + '/api/amounts/getRepairOrderJs',
    getAdvertiserList: HOST + '/api/amounts/getAdvertiserList',//获取代理商列表
    getTicketDetailJs: HOST + '/api/amounts/getTicketDetailJs',//获取代理商列表
    createRepairOrderJs: HOST + '/api/amounts/createRepairOrderJs',//落地页表单提交
    getRepairOrderLog: HOST + '/api/amounts/getRepairOrderLog',//获取最近一次提交的记录
    postqcauth: HOST + '/api/qc/auth',//千川授权
    getaccounts: HOST + '/api/qc/accounts',//获取授权账号列表
    getAuthShops: HOST + '/api/qc/get_auth_shops',//获取授权账号列表
    getShopAdvertisers: HOST + '/api/qc/get_shop_advertisers',//获取授权账号列表
    getReport: HOST + '/api/qc/get_advertiser_report',//获取授权账号列表数据
    getVideoTypeTree: HOST + '/api/dub/getVideoTypeTree',//实拍视频分类
    getMixerList: HOST + '/api/dub/pcGetMixerList',//配音老师
    uploadVideoOrder: HOST + '/api/dub/uploadVideoOrder',//实拍提交表单支付
    getVideoOrderList: HOST + '/api/dub/getVideoOrderList',//实拍订单列表
    videoOrderPayAgain: HOST + '/api/dub/videoOrderPayAgain',//视频订单重新支付
    queryVideoOrder: HOST + '/api/dub/queryPeiyinOrderWechatPayResoult',//查询支付结果
    getVideoOrderInfo: HOST + '/api/dub/getVideoOrderInfo',//视频订单详情
    videoOrderPay: HOST + '/api/dub/videoOrderPay',//确认订单支付接口
    pcGetMixerStyle: HOST + '/api/dub/pcGetMixerStyle',//配音风格
    pcGetHowMoney: HOST + '/api/dub/pcGetHowMoney',//配音费用
    pcUpdateDub: HOST + '/api/dub/pcUpdateDub',//配音提交订单
    pcDubOrderPay: HOST + '/api/dub/pcDubOrderPay',//配音支付
    pcGetDubOrderInfo: HOST + '/api/dub/pcGetDubOrderInfo',//配音详情
    queryPeiyinOrderWechatPayResoult: HOST + '/api/dub/queryPeiyinOrderWechatPayResoult',//配音查询支付结果
    pcGetDubOrderList: HOST + '/api/dub/pcGetDubOrderList',//配音订单列表
    pcDubOrderPayByJsapi: HOST + '/api/dub/pcDubOrderPayByJsapi',//配音移动端支付
    videoOrderPayByJsapi: HOST + '/api/dub/videoOrderPayByJsapi',//实拍移动端支付
    personalMsg: HOST + '/api/amounts/personalMsg',//获取个人信息
    sendMobileMsg: HOST + '/api/amounts/sendMobileMsg',//绑定手机号发送验证码
    bindMobile: HOST + '/api/amounts/bindMobile',//绑定手机号
    pcGetRecordFeedblck: HOST + '/api/dub/pcGetRecordFeedblcks',//修改配音
    pcGetRecordList: HOST + '/api/dub/pcGetRecordList',//配音下载文件列表
    updateAdv: HOST + '/api/calculator/updateAdv',//广告币计算
    getAdvList: HOST + '/api/calculator/getAdvList',//广告币列表
    delAdv: HOST + '/api/calculator/delAdv',//广告币计算删除记录
    getCompanyList: HOST + '/api/top/getCompanyList',//获取企业列表
    getAgentAccount: HOST + '/api/top/getAgentAccount',//企业下对应的账户列表
    getImageOcr: HOST + '/api/top/getImageOcr',//识别文件金额
    placeTheOrder: HOST + '/api/top/placeTheOrder',//自助充值下单接口
    getOrderList: HOST + '/api/top/getOrderList',//自助充值列表
    // webLog: HOST + '/erlangcha/webLog',//webLog
    webLog: HOST + '/serverapi/logs/weblog',//webLog
    portCreate: HOST + '/api/port/create',//创建跨端口实时转账
    portget_data: HOST + '/api/port/get_data',//创建跨端口实时转账订单列表
    roi_create: HOST + '/api/profit/create',//出价盈亏计算器
    roi_get_data: HOST + '/api/profit/get_data',//创建跨端口实时转账订单列表
    updateVideoFeedback: HOST + '/api/dub/updateVideoFeedback',//实拍过审反馈
    proposal: HOST + '/erlangcha/proposal',//建议提交
    updateRoi: HOST + '/api/calculator/updateRoi',//建议提交 
    getRoiList: HOST + '/api/calculator/getRoiList',//建议提交 
    pcDubOrderPayByIntegral: HOST + '/api/dub/pcDubOrderPayByIntegral',//配音积分支付 
    pcVideoOrderPayByIntegral: HOST + '/api/dub/pcVideoOrderPayByIntegral',//实拍积分支付 
    dubOrderDownloadFile: HOST + '/api/dub/dubOrderDownloadFile',//配音下载文件
    getOrderDetails: HOST + '/api/top/getOrderDetails',//配音下载文件
    getPortForAdvertiser: HOST + '/api/account/getPortForAdvertiser',//匹配代理商
    getAppointTimer: HOST + '/api/top/getAppointTimer',//获取到账时间列表
    topIndex: HOST + '/api/top/topIndex',//获取到账时间列表
    getNowTime: HOST + '/api/dub/getNowTime',//配音实拍获取当前时间然后提示
    getLimitDiagnosis: HOST + '/erlangcha/getLimitDiagnosis',//限流诊断
    limitMerchants: HOST + '/erlangcha/limitMerchants',//限流诊断提交框 
    uploadDubVideoCase: HOST + '/api/dub/uploadDubVideoCase',//限流诊断提交框 
    dubOverTime: HOST + '/api/dub/dubOverTime',//配音提交时间获取  

    getDubVerbalTrick: HOST + '/api/dub/getDubVerbalTrick',//配音随机一条提示

    
    file_upload:HOST + '/api/file/upload',//复审上传
    uploadRepairOrderFile:HOST + '/api/amounts/uploadRepairOrderFile',//复审上传就复审用

    exp_Top: HOST + '/api/exp/top',//充值体验
    exp_Repair: HOST + '/api/exp/repair',//复审体验
    get_user_profile: HOST + '/serverapi/user/data/get_user_profile',//获取用户登录信息
    logout: HOST + '/serverapi/user/data/logout',//用户退出登录
    accept_order: HOST + '/api/amounts/netflow/accept_order',//流量工单提交
    keywordRelated: HOST + '/api/red_blue_sea/keywordRelated',//投放对象 热词搜索
    industry: HOST + '/api/red_blue_sea/industry',//投放对象 行业列表
    keywordCloud: HOST + '/api/red_blue_sea/keywordCloud',//查询投放对象 
    keywordRecomm: HOST + '/api/red_blue_sea/keywordRecomm',//创意标签 
    hotIndustry: HOST + '/api/red_blue_sea/hotIndustry',//系统推荐词

    mediaList: HOST + '/api/media/mediaList',//我的媒体列表
    mediaUpload: HOST + '/api/media/mediaUpload',//上传媒体文件
    getAudioResult: HOST + '/api/media/getAudioResult',//获取结果
    agentAccountSearchRes: HOST + '/api/top/agentAccountSearchRes',//通过 agentAccount 查询对应的数据

    createCustomRepairOrder: HOST + '/api/amounts/createCustomRepairOrder',//工单复审提交
    getIndexOrderList: HOST + '/api/top/getIndexOrderList',//工单复审提交
}