
import axios from 'axios';
import { MessageBox, Message } from "element-ui";
import md5 from 'js-md5';
import onLoginApp from './onLoginApp';
axios.defaults.withCredentials = true
var serivce = {
    get: function (url, params, callback) {
        if(localStorage.getItem('TZ-USER')){
           var token = JSON.parse(localStorage.getItem('TZ-USER')).token 
        //    var token = process.env.NODE_ENV == 'development' ? 'b09b8c771f0a359d59066f034dce05f0' : JSON.parse(localStorage.getItem('TZ-USER')).token 
        }
        var req = {
            url: url,
            method: "get",
            params: params,
            headers:{
                token:token ? token : ''
            }
        }
        axios.request(req).then(res => {
            callback(res.data);
            if(res.data.status_code == 0){
                return callback(res.data);
            }
            if(res.data.status_code == '5001'){
                localStorage.removeItem("TZ-USER")
                // this.$confirm('登录失效，请重新登录', '提示', {
                //     confirmButtonText: '确定',
                //     cancelButtonText: '取消',
                //     type: 'warning'
                // }).then(() => {
                //     window.location.href = process.env.VUE_APP_URL_LOGIN_API + '/user/login/wechat_login?redirect_url=' + escape(window.location.href);
                // }).catch(() => {
                          
                // });
            }
            if(res.data.code == '5001'){
                localStorage.removeItem("TZ-USER")
                window.location.href = '/';
                // window.location.href = process.env.VUE_APP_URL_LOGIN_API + '/user/login/wechat_login?redirect_url=' + escape(window.location.href);
            } if(res.data.code == '5002'){
                setTimeout(() => {
                    window.location.href = '/mobphone';
                }, 1000);
            } if(res.data.code == '5005'){
                window.location.href = '/mobilePhone' + window.location.search;
            } 
            if(res.data.code == '400'){
                Message({
                    message: 'error',
                    type: "error",
                });
            }if(res.data.code != 200){
                Message({
                    message: res.data.message ? res.data.message : res.data.msg ? res.data.msg : 'error',
                    type: "error",
                });
            }else {
            }
            
        }).catch((err)=>{
            Message({
                message: '网络繁忙，请稍后再试',
                type: "error",
                duration: 5 * 1000
            });
            console.log(err + url + '请检查网络');
        });
    }

    , post: function (url, params, callback) {
        if(localStorage.getItem('TZ-USER')){
        //    var token = process.env.NODE_ENV == 'development' ? 'b09b8c771f0a359d59066f034dce05f0' : JSON.parse(localStorage.getItem('TZ-USER')).token 
           var token = JSON.parse(localStorage.getItem('TZ-USER')).token
        }
        axios.request({
            url: url,
            method: "post",
            dataType: 'json',
            headers:{
                token:token ? token : ''
            },
            data: params,
        }).then(res => {
            callback(res.data);
            if(res.data.status_code == 0){
                return callback(res.data);
            }if(res.data.status_code == '5001'){
                localStorage.removeItem("TZ-USER")
                // this.$confirm('登录失效，请重新登录', '提示', {
                //     confirmButtonText: '确定',
                //     cancelButtonText: '取消',
                //     type: 'warning'
                // }).then(() => {
                //     window.location.href = process.env.VUE_APP_URL_LOGIN_API + '/user/login/wechat_login?redirect_url=' + escape(window.location.href);
                // }).catch(() => {
                          
                // });
                // window.location.href = process.env.VUE_APP_URL_LOGIN_API + '/user/login/wechat_login?redirect_url=' + escape(window.location.href);
            }if(res.data.code == '5001'){
                localStorage.removeItem("TZ-USER")
                // window.location.href = process.env.VUE_APP_URL_LOGIN_API + '/user/login/wechat_login?redirect_url=' + escape(window.location.href);
                window.location.href = '/';
            } if(res.data.code == '5005'){
                window.location.href = '/mobilePhone';
            } 
            if(res.data.code != 200){
                Message({
                    message: res.data.message ? res.data.message : res.data.msg ? res.data.msg : 'error',
                    type: "error",
                });
            }else {
            }
        }).catch(err=>{
            Message({
                message: '网络繁忙，请稍后再试',
                type: "error",
                duration: 5 * 1000
            });
            console.log(err,1);
        });
    }
}

export default serivce;


