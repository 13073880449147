<template>
	<div class="index">
		<app-HeadTop ref="HeadTop"></app-HeadTop>
		<div class="idxmain">
			<div :class="!$route.meta.main ? 'main-nei' : 'idxmain-nei'">
				<router-view :timeValue="timeValue" :accoutValue="accoutValue"></router-view> 
			</div>
			<div class="feedback" @click="dialogDeedback = true"><i class="iconfont icon-tijiaodingdan"></i>建议反馈</div>
		</div>
		<app-menuBar ref="menuBar"></app-menuBar>
		<el-dialog class="feedback" title="参与调研，助力二郎查工具箱提升服务质量"  :visible.sync="dialogDeedback" width="600px" append-to-body>
			<div class="tiaobiao">
				<p>如何改进能让您更加愿意推荐二郎查工具箱？</p>
				<p><el-input type="textarea" :rows="3" placeholder="请输入您的建议" v-model="textarea"></el-input></p>
			</div>
			<div class="tijiao"><el-button type="primary" @click="_onProposal">建议提交</el-button></div>
			</el-dialog>
	</div>
</template>

<script>
import HeadTop from '@/views/Allpubilc/headTop';
import menuBar from '@/views/Allpubilc/menuBar';
import moment from "moment";//时间解析
export default {
	data () {
		return {
			timeValue:{
				online_start_time:moment(new Date()).format("YYYY-MM-DD"),
				online_end_time:moment(new Date()).format("YYYY-MM-DD"),
			},
			accoutValue:'',
        	dialogDeedback:false,
			textarea:"",//建议
			radio:10,
		}
	},

	components:{
		'app-HeadTop':HeadTop,
		'app-menuBar':menuBar,
	},
	
	watch:{
		// $route(){
		// 	if(this.$route.path != '/dataShow'){
		// 		this.onUserToken()
		// 	}
		// }
	},
	mounted(){
	},

	methods: {
		onUserToken(){
			console.log(this.$route.meta.title)
			if(this.$route.meta.title == '绑定手机号'){return}
			var prame = {
				token:this.$route.query.token
			}
			this.$service.get(this.$api.personalMsg,prame, (res)=> {
				if(res.code == '200'){
					localStorage.setItem('TZ-USER', JSON.stringify(res.data))
				}
			})
		},
		//日期选择
		// onPickerhead(time){
		// 	this.timeValue = time 
		// },
		//按钮登录
		toLogon(){

		},
		//切换
		onmenuBarBlock(){
			this.$refs.menuBar.onmenuBarBlock()
		},
		accoutValueClick(e){
			this.accoutValue = e
		},
		// 建议提交
		_onProposal(){
			if(this.radio == ''){this.$message({message: '请选择评分后提交',type: 'warning'});return}
			var prame = {
				proposal_desc:this.textarea,
				score:this.radio
			}
			this.$service.post(this.$api.proposal,prame, (res)=> {
				if(res.code == '200'){
					this.$message({ message: '提交成功', type: 'success' });
					this.textarea = ''
					this.radio = ''
					this.dialogDeedback = false
				}
			})
			
		},
	}
}
</script>

<style lang="scss" scoped>
.index{
	.idxmain{
		min-height:100vh;
		background: #f9f9f9;
		padding-top: 80px;
		padding-left: 90px;
		padding-right: 20px;
		padding-bottom: 80px;
		position: relative;
		.main-nei{
			padding: 20px;
			background: #fff;
		}
		.idxmain-nei{
			width: 100%;
		}
		.feedback{
			margin-top: 30px;
			padding: 20px 0;
			left: 70px;
			right: 0;
			bottom: 0;
			cursor: pointer;
			width: 100%;
			text-align: center;
			color: #999;
			background: #fff;
			position: absolute;
			i{
				margin-right: 10px;
			}
		}
	}
}
@media screen and (max-width: 750px) {
	.index{
		.idxmain{
			min-height: calc(100vh - 1.2rem);
			background: #fff;
			padding: 1.2rem .25rem 1.5rem .25rem;
			.feedback{
				left: 0;
			}
			.main-nei{
				padding: 0;
			}
		}
		
	}
}
	
</style>
<style lang="scss">
	.feedback{
  .el-dialog__header{
    background-color:#4277f5;
    padding: 18px 20px;
    .el-dialog__title{
      color: #fff;
    }
  }
  .el-dialog__headerbtn{
    top: 10px;
    i{
      color: #fff;
    }
  }
  .el-dialog__body{
    padding: 0;
  }
  .tiaobiao{
    padding: 10px 20px 0 20px;
    overflow: hidden;
    font-size: 14px;
    p{
      margin: 15px 0 0 0;
    }
    span{
      font-size: 14px;
      color: #999;
    }
    .xing{
      color: #f00;
    }
    .xuan{
      overflow: hidden;
      margin-top: 5px;
      span{
        font-size: 12px;
      }
      .left{
        float: left;
      }
      .right{
        float: right;
      }
    }
    
    .el-radio{
      margin-right: 22px;
      .el-radio__label{
        padding-left: 4px;
      }
    }
    .el-radio:last-child{
      margin-right: 0;
    }
  }
  .tijiao{
    background: #ebecf5;
    margin-top: 20px;
    padding: 20px;
    text-align: center;
    span{
      color: #fff;
    }
  }
  
}
</style>
