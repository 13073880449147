import Vue from "vue";
import VueRouter from "vue-router";
import Allpubilc from "@/views/Allpubilc/index";

Vue.use(VueRouter);

const routerApp = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: "/",
            component: Allpubilc,
            redirect: "/",
            children: [
                {
                    path: '/index',
                    component: () => import('@/views/index/index'),
                    meta: { title: "首页", },
                }, {
                    path: '/',
                    component: () => import('@/views/index/about'),
                    meta: { title: "首页", main: true, },
                },
                {
                    path: '/remand',
                    component: () => import('@/views/remand/index'),
                    meta: { title: "工单计划", },
                }, {
                    path: '/workList',
                    component: () => import('@/views/workList/index'),
                    meta: { title: "工单复审", },
                }, {
                    path: '/workList/createIng',
                    component: () => import('@/views/workList/createIng'),
                    meta: { title: "工单复审-创建工单", },
                }, {
                    path: '/dataShow',
                    component: () => import('@/views/dataShow/index'),
                    meta: { title: "消耗成本数据", account: true },
                }, {
                    path: '/voice',
                    component: () => import('@/views/voice/index'),
                    meta: { title: "配音下单", },
                }, {
                    path: '/tofilm',
                    component: () => import('@/views/tofilm/index'),
                    meta: { title: "实拍下单", },
                }, {
                    path: '/tofilm/tofilmList',
                    component: () => import('@/views/tofilm/tofilmList'),
                    meta: { title: "实拍下单-实拍订单列表", },
                }, {
                    path: '/tofilm/orderinfo',
                    component: () => import('@/views/tofilm/orderinfo'),
                    meta: { title: "实拍下单-实拍订单详情", },
                }, {
                    path: '/tofilm/enterorder',
                    component: () => import('@/views/tofilm/enterorder'),
                    meta: { title: "实拍下单-实拍确认订单", },
                }, {
                    path: '/voice/voicemerkorder',
                    component: () => import('@/views/voice/voicemerkorder'),
                    meta: { title: "配音下单-配音确认订单", },
                }, {
                    path: '/voice/voiceList',
                    component: () => import('@/views/voice/voiceList'),
                    meta: { title: "配音下单-配音订单列表", },
                }, {
                    path: '/voice/voiceinfo',
                    component: () => import('@/views/voice/voiceinfo'),
                    meta: { title: "配音下单-配音详情信息", },
                }, {
                    path: '/about',
                    component: () => import('@/views/Allpubilc/about'),
                    meta: { title: "测试页面", },
                }, {
                    path: '/counter',
                    component: () => import('@/views/counter/counter'),
                    meta: { title: "充值计算器", },
                }, {
                    path: '/rorcounter',
                    component: () => import('@/views/counter/rorcounter'),
                    meta: { title: "出价盈亏计算器", },
                }, 
                // {
                //     path: '/transfer',
                //     component: () => import('@/views/transfer'),
                //     meta: { title: "跨端口实时转款", },
                // }, 
                {
                    path: '/transfer',
                    component: () => import('@/views/transfer/transferList'),
                    meta: { title: "跨端口实时转款", },
                }, 
                {
                    path: '/transfer/transferList',
                    component: () => import('@/views/transfer/transferList'),
                    meta: { title: "跨端口实时转款-跨端口实时转款列表", },
                }, {
                    path: '/advances',
                    component: () => import('@/views/advances'),
                    meta: { title: "充值工具", },
                }, {
                    path: '/advances/advancesList',
                    component: () => import('@/views/advances/advancesList'),
                    meta: { title: "充值工具-自助充值列表", },
                }, {
                    path: '/calculator',
                    component: () => import('@/views/counter/calculator'),
                    meta: { title: "二郎查官网搬运-利润计算", },
                }, {
                    path: '/ratio',
                    component: () => import('@/views/counter/ratio'),
                    meta: { title: "投比计算器", },
                }, {
                    path: '/mobphone5g',
                    component: () => import('@/views/mobphone/index5g'),
                    meta: { title: "绑定手机号移动端", }
                }, {
                    path: '/integralmall',
                    component: () => import('@/views/integralmall'),
                    meta: { title: "积分商城", }
                },
                {
                    path: '/experience/advances',
                    component: () => import('@/views/experience/advances'),
                    meta: { title: "体验版自助充值", }
                }, {
                    path: '/experience/workList/createIng',
                    component: () => import('@/views/experience/workList/createIng'),
                    meta: { title: "体验版复审工单", }
                }, {
                    path: '/ticketCreate',
                    component: () => import('@/views/workList/ticketCreate'),
                    meta: { title: "掉量超成本", }
                }, {
                    path: '/hotwords',
                    component: () => import('@/views/hotwords/index'),
                    meta: { title: "素材标签", }
                },{
                    path: '/medium',
                    component: () => import('@/views/medium'),
                    meta: { title: "媒体文件转文字", }
                },{
                    path: '/mobilePhone',
                    component: () => import('@/views/Allpubilc/mobilePhone'),
                    meta: { title: "绑定手机号", }
                },
            ]
        }, {
            path: '/workList/details',
            component: () => import('@/views/workList/details'),
            meta: { title: "工单复审-工单列表反馈详情", },
        }, {
            path: '/return',
            component: () => import('@/views/dataShow/return'),
            meta: { title: "数据绑定接收", },
        }, {
            path: '/advances/Advimage',
            component: () => import('@/views/advances/Advimage'),
            meta: { title: "充值工具-自助充值列表-上传图片预览", },
        }, {
            path: '/mobphone',
            component: () => import('@/views/mobphone'),
            meta: { title: "绑定手机号pc端", },
        }, {
            path: '/userinfo',
            component: () => import('@/views/mobphone'),
            meta: { title: "个人中心", },
        },{
            path: '/diagnose',
            component: () => import('@/views/diagnose'),
            meta: { title: "二郎查官网搬运-限流诊断", }
        }, {
            path: '/tokenLogin',
            component: () => import('@/views/login/tokenLogin'),
            meta: { title: "token信息登录", }
        }, 
    ],
    //Vue路由页面之间滚动位置互相影响
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
})
routerApp.beforeEach((to, from, next) => {
    if (to.matched.length === 0) { //匹配前往的路由不存在
        from.name ? next({
            name: from.name
        }) : next('/'); //判断此跳转路由的来源路由是否存在，存在的情况跳转到来源路由，否则跳转到404页面
    } else {
        // //如果匹配到正确跳转
        // // 配置路由权限判断是否登录
        // if(sessionStorage.getItem('TZ-USER')){
        //     next()
        // }else{
        //     if(to.fullPath != '/login'){
        //         Message({
        //             showClose: true,
        //             message: '请先登录!',
        //             type: "warning"
        //         });
        //         console.log(1)
        //         next('/login')
        //     }
        //     next()
        // }
        next()
    }
    // 
});
const routes = [
    // {
    // 	path: "/",
    // 	component: Allpubilc,
    // 	redirect: "/",
    // 	children: [
    // 		{
    // 			path: '/',
    // 			component: () => import('@/views/index/index'),
    // 			meta: { title: "首页", },
    // 		},
    // 	]
    // }
    //   {
    //     path: "/about",
    //     name: "About",
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () =>
    //       import(/* webpackChunkName: "about" */ "../views/About.vue"),
    //   },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default routerApp;
