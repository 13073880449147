<template>
	<div class="Login">
		<el-dialog title="提示" :visible.sync="dialogVisible" width="750px !important" custom-class="denglu" :before-close="onLoginGuan" append-to-body modal-append-to-body>
			<i class="el-icon-close guanbi" @click="onloginclose"></i>
			<iframe  ref="iframeLogin" :src="loginUrl+'/apiserver/login/index?s_url='+href" frameborder="0" width="750" height="375" scrolling="no"  style="overflow: hidden"></iframe> 
		</el-dialog>
	</div>
</template>

<script>
export default {
	data () {
		return {
			href:window.location.href,
			dialogVisible:false,
		}
	},

	mounted(){
		console.log(this.loginUrl)
		if(!this.$route.query.token && !localStorage.getItem('TZ-USER')){
			if(!this._isMicrom){
				window.location = this.loginUrl+'/apiserver/login/index?s_url=' + window.location.href
			}else{
				this.onLogin() 
			}
		}
		// 移动端登录
		if(this.$route.query.token && !localStorage.getItem('TZ-USER')){
			this.onUserToken()
		}
	},

	methods: {
		//按钮登录打开窗口
		toLogon(){
			this.dialogVisible = true
		},
		onLogin(){
			if(localStorage.getItem('TZ-USER')){
				this.dialogVisible = false
			}else{
				this.dialogVisible = true
				window.addEventListener('message',function(event){
					// console.log(event.data)
					if(event.data.token){
						localStorage.setItem('TZ-USER', JSON.stringify(event.data))
					}
				}, false)
			}
		},
		onLoginGuan(){
			this.$refs.iframeLogin.contentWindow.postMessage('msg',this.loginUrl+'/apiserver/login/index');
			this.dialogVisible=false
		},
		onUserToken(){
			console.log(this.$route.meta.title)
			if(this.$route.meta.title == '绑定手机号'){return}
			var prame = {
				token:this.$route.query.token
			}
			this.$service.get(this.$api.personalMsg,prame, (res)=> {
				if(res.code == '200'){
					localStorage.setItem('TZ-USER', JSON.stringify(res.data))
					if(this.$route.query.token){
						window.location = window.location.origin + '/'
					}
				}
			})
		},
	}
}
</script>

<style lang="scss" scoped>
.remand{
	.denglu{
		.el-dialog__header{
			display: none;
		}
	}
}
</style>
<style lang="scss">
.denglu{
	background: none;
	border-radius: 10px;
		overflow: hidden;
	height: 375px;
	.el-dialog__header{
		display: none;
	}
	.el-dialog__body{
		height: 375px;
		padding: 0;
		
	}
}
.guanbi{
			color: #333 !important;
			
		}
</style>
