<template>
    <div class="menuBarIdx">
        <div class="menuBar" :class="menuBarBlock ? 'menuBarBlock' :'menuBarnone'">
            <el-menu :default-active="path" :collapse="!_isMobile">
                <template v-for="(item, index) in BarData">
                    <el-menu-item :index="item.path" :key="index" class="menu" @click="toPath(item)">
                        <i class="iconfont" :class="item.icon"></i>
                        <i v-if="item.hot" class="iconfont icon-jhot icon"></i>
                        <span slot="title">{{item.title}}</span>
                    </el-menu-item>
                </template>
            </el-menu>
        </div>
        <div class="menuBarBeijing" :class="menuBarBlock ? 'menuBarBlock' :'menuBarnone'" @click="onmenuBarBlock"></div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            menuBarBlock: false,
            path: "",
            BarData: [
                // {
                //     title: "首页",
                //     path: "/",
                //     icon: "icon-shouye1",
                // },
                {
                    title: "充值工具",
                    path: "/advances",
                    icon: "icon-3",
                },
                {
                    title: "工单复审",
                    path: "/workList",
                    icon: "icon-gongdan2",
                },
                {
                    title: "掉量超成本",
                    path: "/ticketCreate",
                    icon: "icon-tijiaodingdan",
                },
                {
                    title: "消耗成本数据",
                    path: "/dataShow",
                    icon: "icon-shuju2",
                },
                {
                    title: "配音下单",
                    path: "/voice",
                    icon: "icon-peiyin",
                },
                // {
                //     title: "实拍下单",
                //     path: "/tofilm",
                //     icon: "icon-labe_shipai",
                // },
                {
                    title: "充值计算器",
                    path: "/counter",
                    icon: "icon-jisuanqi5",
                },

                {
                    title: "出价盈亏计算器",
                    path: "/rorcounter",
                    icon: "icon-yingkuixitong",
                },
                {
                    title: "跨端口实时转款",
                    path: "/transfer",
                    icon: "icon-zhuanzhang2",
                },
                {
                    title: "素材标签",
                    path: "/hotwords",
                    icon: "icon-paiming",
                    hot: true,
                },
                {
                    title: "投比计算器",
                    path: "/ratio",
                    icon: "icon-fangjiajisuanqi",
                },
                {
                    title: "利润计算器",
                    path: "/calculator",
                    icon: "icon-jisuanqi6",
                },
                {
                    title: "限流诊断",
                    path: "/diagnose",
                    icon: "icon-zhenduan1",
                },
                {
                    title: "媒体文件转文字",
                    path: "/medium",
                    icon: "icon-saomiaoshibie380",
                },
                // {
                // 	title:'积分商城',
                // 	path:'/integralmall',
                // 	icon:'icon-icon_shangcheng-mian',
                // },
            ],
        };
    },
    watch: {
        // 监测路由变化,只要变化了就调用获取路由参数方法将数据存储本组件即可
        $route: "onRouteChanged",
    },

    mounted () {
        this.onRouteChanged();
    },

    methods: {
        toPath (item) {
            console.log(item)
            if (item.title == '限流诊断') {
                window.open(window.location.origin + item.path);
                location.reload();
            } else if (this.$route.path == item.path) {
                location.reload();
            } else {
                this.$router.push(item.path)
            }
        },
        onRouteChanged () {
            this.path = this.$route.path;
        },
        onmenuBarBlock () {
            this.menuBarBlock = !this.menuBarBlock;
        },
    },
};
</script>

<style lang="scss" scoped>
.menuBar {
    position: fixed;
    left: 0;
    top: 60px;
    bottom: 0;
    width: 70px;
    background: #fff;
    z-index: 100;
    overflow-y: auto;
    overflow-x: hidden;
    direction: rtl;
    /*滚动条样式*/
    &::-webkit-scrollbar {
        width: 2px;
        /*height: 4px;*/
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.2);
    }
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 0;
        background: rgba(0, 0, 0, 0.1);
    }
    .iconfont {
        font-size: 20px;
    }
    // .iconfont:nth-child(8) {
    //     font-size: 40px !important;
    // }
}

@media screen and (max-width: 750px) {
    .menuBarBeijing {
        position: fixed;
        left: 0;
        top: 1rem;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.2);
        z-index: 99;
        &.menuBarnone {
            display: none;
        }
        &.menuBarBlock {
            display: block;
        }
    }
    .menuBar {
        top: 1rem;
        width: 3rem;
        text-align: left;
        &.menuBarBlock {
            left: 0;
            animation-name: example1;
            animation-duration: 1s;
        }
        &.menuBarnone {
            left: -3rem;
            animation-name: example2;
            animation-duration: 1s;
        }
        @keyframes example1 {
            0% {
                left: -3rem;
            }
            100% {
                left: 0;
            }
        }
        @keyframes example2 {
            0% {
                left: 0;
            }
            100% {
                left: -3rem;
            }
        }
    }
}
</style>
<style lang="scss">
.menuBar {
    .el-menu {
        width: 100%;
        background: none;
        border: none;
        .el-menu-item {
            height: 62px;
            line-height: 62px;
            margin-left: 16px;
            position: relative;
            padding: 0;
            &.is-active {
                background: #f9f9f9;
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
            }
            .el-tooltip {
                padding-left: 17px !important;
            }
            .icon {
                position: absolute;
                top: -10px;
                right: -5px;
                font-size: 25px;
                color: red;
            }
            // .iconfont {
            //     margin-right: 0.2rem;
            // }
        }
    }
}

@media screen and (max-width: 750px) {
    .menuBar {
        .el-menu {
            .el-menu-item {
                margin-left: 0;
                &.is-active {
                    border-radius: 0;
                }
            }
        }
    }
}
</style>
