import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/base.css';
import './assets/css/index.css';
import service from './common/service'
import api from './common/api'
import VueCookies from 'vue-cookies'
import axios from 'axios'
import "@/assets/font/iconfont.css";
import Clipboard from 'v-clipboard'//复制文本

import ECharts from 'vue-echarts'
import onLoginApp from './common/onLoginApp'//登录

//图片幻灯片放大缩小
import VueZoomer from 'vue-zoomer'
Vue.use(VueZoomer)

// 复制文本
import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard);

// 手动引入 ECharts 各模块来减小打包体积
Vue.component('v-chart', ECharts)
Vue.use(Clipboard)// 复制文本;
Vue.use(VueCookies)
Vue.use(ElementUI);
// Vue.prototype.$service = service
Vue.config.productionTip = false;
Vue.prototype.$axios = axios
// aue.prototype.baseURL=process.env.NODE_ENV
Vue.prototype.$service = service
Vue.prototype.$api = api


Vue.prototype.loginUrl = process.env.VUE_APP_URL_LOGIN //登录url
Vue.prototype.IndexUrl = process.env.VUE_APP_URL //接口url
// 全局注册组件（也可以使用局部注册）


let Base64 = require('js-base64').Base64
Vue.prototype.Base64 = Base64 
// 全局方法
var lououtmain = false

Vue.prototype.Logout = lououtmain
// 添加判断方法
Vue.prototype._isMobile = isMobile()

Vue.prototype.onLoginApp = onLoginApp//远程登录

// Vue.prototype.onLoginApp = function(){//远程登录
    // window.location.href = process.env.VUE_APP_URL_LOGIN_API + '/user/login/wechat_login?redirect_url=' + escape(window.location.href);
// }
function isMobile (){
    if(navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)){
        return true
    }else{
        return false
    }
}

//下方全局方法

//判断是否是微信浏览器
Vue.prototype._isMicrom = isMicrom()
function isMicrom(){
    var ua = navigator.userAgent.toLowerCase();//获取判断用的对象
    if(ua.match(/MicroMessenger/i) != "micromessenger"){
        return true //不是微信浏览器
    }else{
        return false //是微信浏览器
    }
}

//周六日提示

//时间转换
Vue.prototype.dateFtt =function(fmt,date) { //author: meizz 
    var o = { 
    "M+" : date.getMonth()+1,     //月份 
    "d+" : date.getDate(),     //日 
    "h+" : date.getHours(),     //小时 
    "m+" : date.getMinutes(),     //分 
    "s+" : date.getSeconds(),     //秒 
    "q+" : Math.floor((date.getMonth()+3)/3), //季度 
    "S" : date.getMilliseconds()    //毫秒 
    }; 
    if(/(y+)/.test(fmt)) 
    fmt=fmt.replace(RegExp.$1, (date.getFullYear()+"").substr(4 - RegExp.$1.length)); 
    for(var k in o) 
    if(new RegExp("("+ k +")").test(fmt)) 
    fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length))); 
    return fmt; 
}
Vue.prototype.fangfa = function (type_name){
  return Base64.decode(type_name)
};
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
